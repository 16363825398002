<template>
  <v-card class="pa-0 ma-0" elevation="0" flat>
    <v-overlay :value="loading" absolute color="white" opacity=".8">
      <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <slot></slot>
  </v-card>
</template>

<script>

export default {
  name: "LoadingWrapper",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>