<template>
  <v-autocomplete
    multiple
    :items="currentClassifications"
    :no-data-text="$t('general.fields.noDataAvailable')"
    :clearable="true"
    v-model="filters.classificationTypes"
    item-color="primary"
    item-text="name"
    item-value="id"
    dense
    flat
    outlined
    color="primary"
    :disabled="!(filters.locationType)"
    :menu-props="{ closeOnContentClick: false }"
  >
    <template v-slot:prepend-item>
      <v-list-item @click="() => toggleSelection()">
        <v-list-item-action>
          <v-icon>{{ computeSelectionIcon() }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          {{ filters.classificationTypes.length === currentClassifications.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:label>
      {{ $t('storage.fields.classificationType') }}
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0" small>
        <span>{{ item.name | truncate(18) }}</span>
      </v-chip>
      <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
      >(+{{ currentClassifications.length - 1 }}
                      {{ $t('general.titles.others') }})</span
      >
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-checkbox
        :input-value="attrs.inputValue"
        color="primary"
        class="pt-2 pb-2 mt-0"
        :hide-details="true"
      >
        <template v-slot:label>
          <read-more
            :classes="'mb-0'"
            :text="item.name"
            :max-chars="160"
            :font-size="14"
          />
        </template>
      </v-checkbox>
    </template>
  </v-autocomplete>
</template>

<script>
import ReadMore from "@/components/ReadMoreComponent.vue";
import i18n from "@/plugins/i18n"
import { getByProperty } from "@/utils"
import { mapGetters } from "vuex"

export default {
  name: "ClassificationTypePicker",
  components: { ReadMore },
  watch: {
    'filters.locationType': function (newVal) {
      if (!newVal) {
        this.filters.classificationTypes = [];
      }
    }
  },
  created() {
    this.filters.classificationTypes = this.filters.classificationTypes
      .map(id => this.findClassificationById(id))
      .filter(item => item !== null);

    this.filters.classificationTypes = getByProperty(this.filters.classificationTypes, "id");
  },
  computed: {
    ...mapGetters({
      sClassifications: 'storage/GET_CLASSIFICATIONS',
      lClassifications: 'locations/GET_CLASSIFICATIONS',
    }),
    currentClassifications() {
      let filterEntity = this.filters.locationType;

      if (filterEntity && typeof filterEntity === 'object' && 'key' in filterEntity) {
        filterEntity = filterEntity.key;
      }

      if (!filterEntity) {
        return [];
      }

      if (filterEntity === 'SECONDARY') {
        return this.sClassifications;
      } else if (filterEntity === 'PRIMARY') {
        return this.lClassifications;
      }
      return [];
    },
    filters: {
      get() {
        return this.$store.getters['dashboard/GET_FILTERS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_FILTERS', val);
      }
    },
  },
  methods: {
    findClassificationById(id) {
      const allClassifications = [...this.sClassifications, ...this.lClassifications];
      return allClassifications.find(classification => classification.id === id) || null;
    },
    async toggleSelection() {
      let allItems = this.currentClassifications;
      let selectedItems = this.filters.classificationTypes;

      if (selectedItems.length < allItems.length) {
        this.filters.classificationTypes = allItems.map(item => item.id);
      } else {
        this.filters.classificationTypes = [];
      }
    },
    computeSelectionIcon() {
      let allItems = this.currentClassifications;
      let selectedItems = this.filters.classificationTypes;

      if (selectedItems.length === 0) {
        return 'mdi-checkbox-blank-outline';
      } else if (selectedItems.length === allItems.length) {
        return 'mdi-checkbox-marked';
      } else {
        return 'mdi-minus-box';
      }
    },
  }
}
</script>

<style scoped>

</style>