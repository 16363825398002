import { render, staticRenderFns } from "./ImageWrapper.vue?vue&type=template&id=64dadb9d&scoped=true"
import script from "./ImageWrapper.vue?vue&type=script&lang=js"
export * from "./ImageWrapper.vue?vue&type=script&lang=js"
import style0 from "./ImageWrapper.vue?vue&type=style&index=0&id=64dadb9d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64dadb9d",
  null
  
)

export default component.exports