<template>
  <v-col :cols="cols" class="py-0 pb-4" v-if="showField('classificationTypes')">
    <v-autocomplete v-model="config.filter.classificationTypes" :items="currentClassifications" multiple
      :label="$t('storage.fields.classificationType')" item-value="id" item-text="name" item-color="primary"
      color="primary" outlined dense clearable />
  </v-col>
</template>

<script>
import { ClassificationTypeMixin } from '@/module/dashboard/mixins/classification-type.mixin'

export default {
  name: 'ClassificationTypeField',
  props: {
    config: {
      type: Object,
      required: true
    },
    cols: {
      type: Number,
      required: true,
      default: true
    },
    showField: {
      type: Function,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    }
  },
  mixins: [ClassificationTypeMixin]
};
</script>

<style scoped></style>