<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >

    <template v-slot:custom-fields>
      <v-container class="ma-0 pa-0">
        <v-row>
          <v-col cols="12">
            <v-select
              :items="locationTypes"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.locationType')"
              v-model="config.filter.locationType"
              item-color="primary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="primary"
              :disabled="loading.process"
              :rules="selectRule"
              @change="config.filter.classificationTypes = []"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.locationType') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
          <classification-type-filed
            :config="config"
            :showField="() => true"
            :cols="12"
            filter-key="locationType"
          />
          <v-col cols="6">
            <v-select
              :items="directions"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.order')"
              v-model="config.filter.direction"
              item-color="primary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="primary"
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.order') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="config.filter.active"
              :items="activeOptions"
              :label="$t('widgets.dialogs.filters.status')"
              item-value="value"
              item-text="name"
              item-color="primary"
              color="primary"
              outlined
              dense
              clearable
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@/module/dashboard/components/common/FormComponent.vue";
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
import {GlobalWidgetMixin} from "@/module/dashboard/mixins/global-widget.mixin";
import {CommonWidgetMixin} from "@/module/dashboard/mixins/common-widget.mixin";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import { ClassificationTypeMixin } from "@/module/dashboard/mixins/classification-type.mixin";
import i18n from "@/plugins/i18n";
import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue"
import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue"
import ReadMore from "@/components/ReadMoreComponent.vue"
import { getByProperty } from '@/utils';
import { mapGetters } from "vuex"
import ClassificationTypeFiled from '../../../common/ClassificationTypeFiled.vue';

export default {
  name: 'LocationsByStateDialog',
  components: { ReadMore, AFooterPaginatorField, LoadingWrapper, FormComponent, IconPicker, ColorPicker, ClassificationTypeFiled },
  mixins: [GlobalWidgetMixin, CommonWidgetMixin, FormWidgetMixin, ClassificationTypeMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.LOCATIONS_BY_STATE'),
        icon: 'mdi-grid',
        color: '#232323',
        filter: {
          locationType: null,
          classificationTypes: [],
          direction: null,
          active: null,
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'LOCATIONS_BY_STATE',
        type: 'TABLE'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.LOCATIONS_BY_STATE'),
        icon: 'mdi-grid',
        color: '#232323',
        filter: {
          locationType: null,
          classificationTypes: [],
          direction: null,
          active: null,
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'LOCATIONS_BY_STATE',
        type: 'TABLE'
      },
      locationTypes: [
        {
          key: 'PRIMARY',
          name: i18n.t('locations.fields.name')
        },
        {
          key: 'SECONDARY',
          name: i18n.t('storage.fields.name')
        }
      ],
      directions: [
        {
          key: 'ASC',
          name: 'ASC'
        },
        {
          key: 'DESC',
          name: 'DESC'
        }
      ],
      activeOptions: [
        { id: 1, value: true, name: i18n.t('documents.filters.isActive.activated') },
        { id: 2, value: false, name: i18n.t('documents.filters.isActive.desactivated') }
      ],
      defaultConfig: null,
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config }
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "LOCATIONS_BY_STATE",
      icon: "mdi-grid",
      info: "LOCATIONS_BY_STATE",
    };
  },
  computed: {
    ...mapGetters({
      classifications: "storage/GET_CLASSIFICATIONS",
    }),
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          locationType: this.config.filter.locationType.key || this.config.filter.locationType,
          classificationTypes: getByProperty(this.config.filter.classificationTypes, "id"),
          direction: this.config.filter.direction.key || this.config.filter.direction,
          active: getByProperty(this.config.filter.active, 'value')
        },
        initialized: true
      }

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      if (this.item.initialized) {
        this.config.filter.locationType = _.find(this.locationTypes, { key: this.config.filter.locationType.key || this.config.filter.locationType });

        if (this.config.filter && this.config.filter.classificationTypes)
          this.config.filter.classificationTypes = this.config.filter.classificationTypes
            .map(id => this.findClassificationById(id))
            .filter(item => item !== null);

        this.config.filter.direction = _.find(this.directions, { key: this.config.filter.direction.key || this.config.filter.direction });
        this.config.filter.active = _.find(this.activeOptions, { value:  this.config.filter.active })?this.config.filter.active : null
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
};
</script>

<style scoped>
</style>
