<template>
  <FormComponent :dialog="dialog" :config="config" :valid="!!(valid && isUrlValid)" :extra-valid="isUrlValid" :advanced-options="{format: false, itemsLimit: false}"
    @save:form="saveConfig" @update:dialog="dialog = $event" @update:valid="valid = ($event && isUrlValid)"
    @load:init-data="handleInitData" @clear:form="clear($event)">
    <template v-slot:custom-fields>
      <div v-if="!config.filter.url">
        <v-file-input v-model="config.filter.file" :label="$t('widgets.dialogs.fields.uploadFile')" color="primary"
          outlined dense :rules="requireRules" prepend-icon="" @click:clear="clearData" @change="fileSelected">
          <template v-slot:append-outer>
            <v-btn :disabled="!config.filter.file" icon @click="uploadS3File()" class="cursor-pointer"
              :loading="loading.upload">
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </template>
        </v-file-input>
        <v-alert v-if="config.filter.file && !config.filter.url" type="warning" dense text border="left">
          <span v-html="uploadMessage"></span>
        </v-alert>
      </div>
      <div v-else class="image-preview my-4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img 
              :src="config.filter.url"
              contain
              max-height="300px"
              max-width="300px"
            >
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="white">
                  <v-btn fab @click="clearData">
                    <v-icon color="white">mdi-close-circle</v-icon>
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>
      </div>
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from "@/module/dashboard/components/common/FormComponent.vue"
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue"
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue"
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin"
import { mapActions, mapGetters } from "vuex"
import i18n from "@/plugins/i18n"

export default {
  name: "ImageDialog",
  components: { FormComponent, IconPicker, ColorPicker },
  mixins: [FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.IMAGE'),
        icon: 'mdi-image',
        color: '#232323',
        filter: {
          url: null,
          file: null
        },
        subtype: "IMAGE",
        type: "CARD",
      }),
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      config: {
        id: null,
        title: i18n.t('widgets.names.IMAGE'),
        icon: 'mdi-image',
        color: '#232323',
        filter: {
          url: null,
          file: null,
        },
        subtype: "IMAGE",
        type: "CARD",
      },
      defaultConfig: null,
      loading: {
        upload: false
      }
    }
  },
  watch: {
    valid() {
      this.updateValidation();
    },
    'config.filter.url'(newVal) {
      this.updateValidation();
    }
  },
  async created() {
    this.defaultConfig = { ...this.config }
    this.config = {
      ...this.item.props,
      subtype: "IMAGE",
      icon: "mdi-image",
      info: "IMAGE",
    }
  },
  computed: {
    isEditingEnabled() {
      return this.$store.getters["dashboard/IS_EDITING_MODE"]
    },
    isUrlValid() {
      return !!this.config.filter.url && this.config.filter.url.trim() !== '';
    },
    uploadMessage() {
      return this.$t('widgets.validation.uploadMessage');
    },
  },
  methods: {
    ...mapActions({
      uploadFile: "dashboard/uploadFile",
    }),
    clearData() {
      this.$set(this.config.filter, 'url', null);
      this.$set(this.config.filter, 'file', null);
      this.$emit("update:validation", false);
    },
    async uploadS3File() {
      if (!this.config.filter.file) return;

      this.loading.upload = true;
      await this.uploadFile(this.config.filter.file).then((resp) => {
        this.config.filter.url = resp.url;
      }).finally(() => {
        this.loading.upload = false;
      });
    },
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          url: this.config.filter.url
        },
        initialized: true,
      }

      this.$emit("update:config", config)
      this.$emit("update:validation", this.valid && this.isUrlValid)
      this.dialog = false
    },
    async handleInitData() {
      if (!this.item.initialized) {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = { ...e }
    },
    fileSelected() {
      console.log(this.config.filter.file)
      console.log(this.config.filter.url)
      console.log(!!(this.config.filter.file && this.config.filter.url))
      this.$emit("update:validation", !!(this.config.filter.file && this.config.filter.url));
    },
    updateValidation() {
      console.log(this.valid)
      this.$emit("update:validation", this.valid);
    }
  },
}
</script>

<style scoped>
.image-preview {
  position: relative;
  display: inline-block;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
}

:deep(.v-input__append-outer) {
  margin-top: 5px !important;
}
</style>
