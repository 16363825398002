<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" :scrollable="false">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip right>
          <template v-slot:activator="{ on: tooltip }">
            <v-badge bordered
                     :color="`#a9ffc6`" offset-x="12" offset-y="12">
              <template #badge>
                <v-icon x-small
                        :color="`#1c5d35`">mdi-pencil</v-icon>
              </template>
              <v-btn icon v-bind="attrs" v-on="{ ...on, ...tooltip }" class="icon-picker-button">
                <v-icon size="36px" :color="color">mdi-{{ selectedIcon }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>{{$t("widgets.dialogs.tooltips.iconSelect")}}</span>
        </v-tooltip>
      </template>
      <v-card class="elevation-1">
        <v-card-title class="headline custom-header__card">
          <div class="custom-header__package mb-4" style="width: 100%">
            <p class="ma-0 pa-0">{{$t("widgets.dialogs.icons.title")}} </p>
            <v-spacer />
            <v-autocomplete
              style="width: 50%"
              :label="$t('widgets.dialogs.icons.package')"
              v-model="selectedPackage"
              :items="packages"
              item-text="name"
              item-value="id"
              return-object
              single-line
              auto
              outlined
              dense
              hide-details
              @change="changePackage"
              color="primary"
              item-color="primary"
            />
          </div>

          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('widgets.dialogs.icons.search')"
            single-line
            hide-details
            color="primary"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-for="(icon, index) in paginatedIcons"
                :key="icon.id"
                cols="2" sm="1" md="1"
                @click="selectIcon(icon.name, index)"
                class="icon-col justify-center ma-0 pa-0"
              >
                <v-btn icon>
                  <v-icon size="24">mdi-{{ icon.name }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="primary"
            total-visible="10"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getPackages, getIconsByPackageId } from "@/services/IconsService";

export default {
  name: 'IconPicker',
  props: {
    current: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: '#202020'
    }
  },
  data() {
    return {
      dialog: false,
      search: '',
      packages: [],
      icons: [],
      selectedIcon: 'help-circle',
      selectedIconIndex: -1,
      page: 1,
      iconsPerPage: 168,
      selectedPackage: null,
    };
  },
  async created() {
    let packages = await getPackages();
    packages = packages.sort((a, b) => a.name.localeCompare(b.name));
    this.packages = packages;

    this.selectedIcon = this.current;

    this.icons = await getIconsByPackageId(null);
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.page = 1;
      }
    },
    async selectedPackage(newPackage, oldPackage) {
      if (newPackage !== oldPackage) {
        try {
          this.icons = await getIconsByPackageId(newPackage ? newPackage.name : null);
        } catch (error) {
          console.error('Error al cambiar de paquete', error);
          this.icons = [];
        }
      }
    },
  },
  computed: {
    filteredIcons() {
      return this.icons.filter(icon =>
        icon.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    pageCount() {
      return Math.ceil(this.filteredIcons.length / this.iconsPerPage);
    },
    paginatedIcons() {
      const startIndex = (this.page - 1) * this.iconsPerPage;
      return this.filteredIcons.slice(startIndex, startIndex + this.iconsPerPage);
    }
  },
  methods: {
    selectIcon(icon, index) {
      this.selectedIcon = icon;
      this.selectedIconIndex = index;
      this.$emit('input', `mdi-${this.selectedIcon}`);
      this.dialog = false;
    },
    async changePackage(newPackage) {
      try {
        let newIcons = [];
        if (newPackage) {
          newIcons = await getIconsByPackageId(newPackage.name);
        } else {
          newIcons = await getIconsByPackageId(null);
        }

        this.$nextTick(() => {
          this.icons = newIcons;
          this.page = 1;
          this.selectedPackage = newPackage;
        });
      } catch (error) {
        console.error('Error al cambiar de paquete', error);
        this.icons = [];
      }
    },
  }
};
</script>

<style scoped>
.icon-picker-button .v-icon {
  border-radius: 50%;
  padding: 5px;
}

.icon-list .v-list-item {
  cursor: pointer;
}

.custom-header__card {
  display: flex !important;
  justify-content: space-between !important;
  align-content: center !important;
  flex-direction: row !important;
}

.custom-header__package {
  display: flex !important;
  justify-content: space-between !important;
  align-content: center !important;
}

</style>
