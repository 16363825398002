<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
    :advanced-options="{format: false, itemsLimit: false}"
  >
    <template v-slot:custom-fields>
      <v-autocomplete
        :no-data-text="$t('general.fields.noDataAvailable')"
        item-text="value"
        return-object
        outlined
        dense
        color="primary"
        item-color="primary"
        v-model="dataToCheck"
        :items="layers"
        :disabled="layers.length === 0"
        multiple
        :rules="selectRule"
      >
        <template v-slot:label>
          {{ $t("widgets.dialogs.filters.layers") }}
          <span class="red--text"><strong>*</strong></span>
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ $t("map.layers." + item).toUpperCase() | truncate(18) }}</span>
          </v-chip>
          <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
          >(+{{ dataToCheck.length - 1 }}
                      {{ $t('general.titles.others') }})</span
          >
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 200px">
            <v-checkbox
              :input-value="attrs.inputValue"
              color="primary"
              class="pt-2 pb-2 mt-0"
              :hide-details="true"
            >
              <template v-slot:label>
                <read-more
                  :classes="'mb-0'"
                  :text="$t('map.layers.' + item).toUpperCase()"
                  :max-chars="80"
                  :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
      </v-autocomplete>
    </template>
  </FormComponent>
</template>

<script>
  import ColorPicker from "@dashboard/components/common/ColorPicker.vue"
  import IconPicker from "@dashboard/components/common/IconPicker.vue"
  import FormComponent from "@dashboard/components/common/FormComponent.vue"
  import {CommonWidgetMixin} from "@/module/dashboard/mixins/common-widget.mixin";
  import {AgtraceWidgetMixin} from "@/module/dashboard/mixins/agtrace-widget.mixin";
  import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
  import { mapGetters } from "vuex"
  import i18n from "@/plugins/i18n"
  import ReadMore from "@/components/ReadMoreComponent.vue"

export default {
  name: 'MapDialog',
  components: { ReadMore, FormComponent, IconPicker, ColorPicker },
  mixins: [AgtraceWidgetMixin, CommonWidgetMixin,FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.MAP_BY_LAYERS'),
        icon: 'mdi-chart-arc',
        color: '#232323',
        filter: {
          processIds: null,
          groupIds: null,
          nestedValues: [],
          unitShowed: null
        },
        subtype: 'MAP_BY_LAYERS',
        type: 'CARD'
      })
    }
  },
  data() {
    return {
      dialog: !this.item.initialized,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.MAP_BY_LAYERS'),
        icon: 'mdi-chart-arc',
        color: '#232323',
        filter: {
          nestedValues: [],
        },
        subtype: 'MAP_BY_LAYERS',
        type: 'CARD'
      },
      layers: [
        'l_locations_clusters',
        'l_locations_clusters_count',
        'l_locations_up_zoom',
        'l_locations_down_zoom',
        'l_locations_clusters_points',
        'l_locations_clusters_points_icon',
        'l_locations_center_points',
        'l_locations_center_points_icon',
        'l_locations_names',
        'l_locations_fill_all',
        'l_locations_all',
        'l_storages_clusters',
        'l_storages_clusters_count',
        'l_storages_down_zoom',
        'l_storages_clusters_points',
        'l_storages_clusters_points_icon',
        'l_storages_center_points',
        'l_storages_center_points_icon',
        'l_storages_names',
        'l_storages_all'
      ],
      defaultConfig: null
    };
  },
  created() {
    this.defaultConfig = { ...this.config }
    this.config = {
      ...this.item.props,
      subtype: "MAP_BY_LAYERS",
      icon: "mdi-card-bulleted",
      info: "mdi-MAP_BY_LAYERS",
    };
  },
  computed: {
    ...mapGetters({
      requireRules: 'general/requireRules',
    }),
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          nestedValues: this.dataToCheck
        },
        initialized: true
      }

      this.$emit('update:config', config);
      this.$emit('update:layers');
      this.dialog = false;
    },
    async handleInitData() {
      if (this.item.initialized) {
        this.dataToCheck = this.config.filter.nestedValues;
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = { ...e }
    }
  }
}
</script>

<style scoped>
  .v-card {
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #424242;
  }
</style>