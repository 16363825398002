<template>
  <v-card class="data-chart-card" flat style="height: 100%">
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="cardConfig.color">{{ cardConfig.icon }}</v-icon>
      <span class="title-text">{{ cardConfig.title }}</span>
      <v-spacer />
      <v-btn v-if="preview" @click="preview = !preview" class="my-2 px-5" color="secondary lighten-2" elevation="0" small><span class="text--secondary font-weight-bold"><v-icon left>mdi-chevron-left</v-icon>{{ $t("general.buttons.back")}}</span></v-btn>
    </v-card-title>
    <v-card-text style="height: calc(100% - 90px);">
      <v-container fluid class="fill-height">
        <v-row class="fill-height">
          <v-col class="fill-height">
            <span
              class="d-flex justify-center align-center align-content-center fill-height"
              v-if="isEditingEnabled && !preview"
            >
               <v-card
                 height="250px"
                 max-height="100%"
                 width="100%"
                 class="shadow-none ma-0 pa-0 my-4 elevation-0 d-flex
                  flex-column
                  justify-center align-content-center align-center fill-height"
                 elevation="0"
               >
                <v-chip disabled small>
                  <v-icon small>mdi-alert-outline</v-icon>
                  <p
                    style="font-size: 12px; font-weight: normal"
                    class="ma-0 pa-0 mx-4"
                  >
                    {{ $t('general.ui.not_data_found') }}
                  </p>
                </v-chip>
                <v-btn @click="preview = !preview" class="my-2 px-5" color="secondary lighten-2" elevation="0" small><span class="text--secondary font-weight-bold">{{$t('widgets.preview')}}<v-icon right>mdi-cellphone-link</v-icon></span></v-btn>
               </v-card>
            </span>
            <span v-else>
              <v-autocomplete
                :no-data-text="$t('general.fields.noDataAvailable')"
                :label="$t('documents.fields.type')"
                item-color="primary"
                item-value="id"
                item-text="document_type_name"
                :items="documentTypes"
                v-model="documentFilter"
                outlined
                dense
                color="primary"
                background-color="#EAEAEA80"
                clearable
                hide-details
                chips
                small-chips
                multiple
                @change="documentFiltered"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0">
                    <span>{{ item.document_type_name | truncate(15) }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ documentFilter.length - 1 }}
                    {{ $t("general.titles.others") }})
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <div
                    class="
                      d-flex
                      justify-start
                      align-center align-content-center
                      my-2
                    "
                  >
                    <v-btn
                      @click="selectAllFilters()"
                      color="primary"
                      x-small
                      text
                      class="mx-2"
                    >
                      {{ $t("general.titles.selectAll") }}
                    </v-btn>
                    <v-btn
                      @click="documentFilter = []"
                      color="primary"
                      x-small
                      text
                      class="mx-2"
                    >
                      {{ $t("general.titles.clean") }}
                    </v-btn>
                  </div>
                  <v-divider class="mb-2"></v-divider>
                </template>
              </v-autocomplete>
              <v-card
                class="document-expiry__list ma-0 pa-0 my-4 elevation-0"
                elevation="0"
              >
                <v-overlay
                  :value="loadingDocuments || !loadDocumentReady"
                  absolute
                  color="white"
                  opacity=".8"
                >
                  <v-progress-circular
                    color="primary"
                    indeterminate
                    size="64"
                  />
                </v-overlay>
                <v-list-item
                  @click="
                    documentsStadistic.experied.cantDocument > 0
                      ? toDocuments('red')
                      : ''
                  "
                >
                  <v-list-item-content class="pb-2">
                    <div class="d-flex justify-start">
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 cantDocuments">{{
                            documentsStadistic.experied.cantDocument
                          }}</span>
                          <span class="pr-3 genericTitleDocument">{{
                            $t("home.titles.documentsFilterTitle")
                          }}</span>
                          <span class="documentState">{{
                            $t("home.titles.red")
                          }}</span>
                        </p>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 documentPercent"
                            >{{
                              Math.round(documentsStadistic.experied.percent)
                            }}%</span
                          >
                        </p>
                      </div>
                    </div>
                    <v-progress-linear
                      :height="height"
                      :value="documentsStadistic.experied.percent"
                      color="error"
                      rounded
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="
                    documentsStadistic.yellow.cantDocument > 0
                      ? toDocuments('yellow')
                      : ''
                  "
                >
                  <v-list-item-content class="pb-2">
                    <div class="d-flex justify-start">
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 cantDocuments">{{
                            documentsStadistic.yellow.cantDocument
                          }}</span>
                          <span class="pr-3 genericTitleDocument">{{
                            $t("home.titles.documentsFilterTitle")
                          }}</span>
                          <span class="documentState">{{
                            $t("home.titles.yellow")
                          }}</span>
                        </p>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 documentPercent"
                            >{{
                              Math.round(documentsStadistic.yellow.percent)
                            }}%</span
                          >
                        </p>
                      </div>
                    </div>
                    <v-progress-linear
                      :height="height"
                      :value="documentsStadistic.yellow.percent"
                      color="warning"
                      rounded
                    />
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    documentsStadistic.blue.cantDocument > 0
                      ? toDocuments('blue')
                      : ''
                  "
                >
                  <v-list-item-content class="pb-2">
                    <div class="d-flex justify-start">
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 cantDocuments">{{
                            documentsStadistic.blue.cantDocument
                          }}</span>
                          <span class="pr-3 genericTitleDocument">{{
                            $t("home.titles.documentsFilterTitle")
                          }}</span>
                          <span class="documentState">{{
                            $t("home.titles.blue")
                          }}</span>
                        </p>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 documentPercent"
                            >{{
                              Math.round(documentsStadistic.blue.percent)
                            }}%</span
                          >
                        </p>
                      </div>
                    </div>
                    <v-progress-linear
                      :height="height"
                      :value="documentsStadistic.blue.percent"
                      color="primary"
                      rounded
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="
                    documentsStadistic.gray.cantDocument > 0
                      ? toDocuments('gray')
                      : ''
                  "
                >
                  <v-list-item-content class="pb-2">
                    <div class="d-flex justify-start">
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 cantDocuments">{{
                            documentsStadistic.gray.cantDocument
                          }}</span>
                          <span class="pr-3 genericTitleDocument">{{
                            $t("home.titles.documentsFilterTitle")
                          }}</span>
                          <span class="documentState">{{
                            $t("home.titles.gray")
                          }}</span>
                        </p>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 documentPercent"
                            >{{
                              Math.round(documentsStadistic.gray.percent)
                            }}%</span
                          >
                        </p>
                      </div>
                    </div>
                    <v-progress-linear
                      :height="height"
                      :value="documentsStadistic.gray.percent"
                      color="gray"
                      rounded
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="
                    documentsStadistic.green.cantDocument > 0
                      ? toDocuments('green')
                      : ''
                  "
                >
                  <v-list-item-content class="pb-2">
                    <div class="d-flex justify-start">
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 cantDocuments">{{
                            documentsStadistic.green.cantDocument
                          }}</span>
                          <span class="pr-3 genericTitleDocument">{{
                            $t("home.titles.documentsFilterTitle")
                          }}</span>
                          <span class="documentState">{{
                            $t("home.titles.green")
                          }}</span>
                        </p>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <p class="pb-2 mb-0">
                          <span class="pr-1 documentPercent"
                            >{{
                              Math.round(documentsStadistic.green.percent)
                            }}%</span
                          >
                        </p>
                      </div>
                    </div>
                    <v-progress-linear
                      :height="height"
                      :value="documentsStadistic.green.percent"
                      color="primary"
                      rounded
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import _ from "lodash"
  import moment from "moment/moment"
  import { mapActions, mapGetters } from "vuex"
  import { CardWidgetMixin } from "@dashboard/mixins/card-widget.mixin"
  import { ReloadWidgetMixin } from "@dashboard/mixins/reload-widget.mixin"

  export default {
    mixins: [CardWidgetMixin, ReloadWidgetMixin],
    data() {
      return {
        profile: null,
        loadingDocuments: false,
        documentsStadistic: this.getDocumentsStadistic(),
        documentFilter: null,
        height: 8,
        preview: false
      }
    },
    async created() {
      this.loadingDocuments = true
      await this.fetchListDocuments([this.$toast]).finally(() => {
        this.loadingDocuments = false
      })
    },
    computed: {
      ...mapGetters({
        documentTypes: "document_type/documentTypes",
        documents: "documents/documents",
      }),
      isEditingEnabled() {
        return this.$store.getters["dashboard/IS_EDITING_MODE"]
      },
      filtersDoc: {
        get() {
          return this.$store.getters["ui/GET_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_FILTERS", val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters["ui/GET_OBJECTS_TO_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_OBJECTS_TO_FILTERS", val)
        },
      },
      loadDocumentReady() {
        if (!this.loading) {
          this.documentFiltered()
        }
        return !this.loading
      },
    },
    methods: {
      ...mapActions({
        fetchListDocuments: "documents/fetchListDocuments",
      }),
      getDocumentsStadistic() {
        return {
          experied: {
            cantDocument: 0,
            percent: 0,
          },
          yellow: {
            cantDocument: 0,
            percent: 0,
          },
          blue: {
            cantDocument: 0,
            percent: 0,
          },
          gray: {
            cantDocument: 0,
            percent: 0,
          },
          green: {
            cantDocument: 0,
            percent: 0,
          },
        }
      },
      selectAllFilters() {
        this.documentFilter = _.map(this.documentTypes, "id")
      },
      toDocuments(filterType) {
        let filter = {}
        filter.type = filterType
        filter.types = []
        let docTypes = []
        if (this.documentFilter) {
          _.forEach(this.documentTypes, documentType => {
            if (this.documentFilter.includes(documentType.id)) {
              filter.types.push(
                documentType.document_type_name.charAt(0) +
                  documentType.document_type_name.slice(1).toLowerCase()
              )
              docTypes.push(documentType.id)
            }
          })
        }
        this.$store.commit("ui/CLEAR_LOCAL_FILTERS_FOR_DOCUMENT")
        let filtersDocuments = JSON.parse(
          localStorage.getItem("filtersDocuments")
        )
        // this.$store.commit('ui/')
        filtersDocuments.document_type_ids = docTypes
        filtersDocuments.filtersObjects.document["isActive"] = true
        switch (filterType) {
          case "red":
            filtersDocuments.filtersObjects.document["isExpirated"] = true

            this.$root.$emit("setFiltersDocuments")
            break
          case "yellow":
            filtersDocuments.filtersDocumentDate.expiration_date = [
              moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
              moment(new Date(), "YYYY-MM-DD")
                .add(30, "d")
                .format("YYYY-MM-DD"),
            ]
            filtersDocuments.filtersObjects.document.expiration_date = [
              moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD") +
                "T00:00:00.000Z",
              moment(new Date(), "YYYY-MM-DD")
                .add(30, "d")
                .format("YYYY-MM-DD") + "T23:59:59.000Z",
            ]

            this.$root.$emit("setFiltersDocuments")
            break
          case "blue":
            filtersDocuments.filtersDocumentDate.expiration_date = [
              moment(new Date(), "YYYY-MM-DD")
                .add(31, "d")
                .format("YYYY-MM-DD"),
              moment(new Date(), "YYYY-MM-DD")
                .add(60, "d")
                .format("YYYY-MM-DD"),
            ]
            filtersDocuments.filtersObjects.document.expiration_date = [
              moment(new Date(), "YYYY-MM-DD")
                .add(31, "d")
                .format("YYYY-MM-DD") + "T00:00:00.000Z",
              moment(new Date(), "YYYY-MM-DD")
                .add(60, "d")
                .format("YYYY-MM-DD") + "T23:59:59.000Z",
            ]

            this.$root.$emit("setFiltersDocuments")
            break
          case "gray":
            filtersDocuments.filtersDocumentDate.expiration_date = [
              moment(new Date(), "YYYY-MM-DD")
                .add(61, "d")
                .format("YYYY-MM-DD"),
              moment(new Date(), "YYYY-MM-DD")
                .add(90, "d")
                .format("YYYY-MM-DD"),
            ]
            filtersDocuments.filtersObjects.document.expiration_date = [
              moment(new Date(), "YYYY-MM-DD")
                .add(61, "d")
                .format("YYYY-MM-DD") + "T00:00:00.000Z",
              moment(new Date(), "YYYY-MM-DD")
                .add(90, "d")
                .format("YYYY-MM-DD") + "T23:59:59.000Z",
            ]

            this.$root.$emit("setFiltersDocuments")
            break
          case "green":
            filtersDocuments.filtersDocumentDate.expiration_date = [
              moment(new Date(), "YYYY-MM-DD")
                .add(91, "d")
                .format("YYYY-MM-DD"),
              moment(new Date(), "YYYY-MM-DD")
                .add(30, "years")
                .format("YYYY-MM-DD"),
            ]
            filtersDocuments.filtersObjects.document.expiration_date = [
              moment(new Date(), "YYYY-MM-DD")
                .add(91, "d")
                .format("YYYY-MM-DD"),
              moment(new Date(), "YYYY-MM-DD")
                .add(30, "years")
                .format("YYYY-MM-DD") + "T23:59:59.000Z",
            ]

            this.$root.$emit("setFiltersDocuments")
            break
        }
        localStorage.setItem(
          "filtersDocuments",
          JSON.stringify(filtersDocuments)
        )
        this.$router.push({ path: "/documents" })
      },
      documentFiltered() {
        this.documentsStadistic = this.getDocumentsStadistic()
        let documentsFiltered = Object.assign([], this.documents)
        if (this.documentFilter && this.documentFilter.length) {
          documentsFiltered = _.filter(documentsFiltered, document => {
            return this.documentFilter.includes(document.documentType.id)
          })
        }
        const total = documentsFiltered.length
        const today = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
        const today30 = moment(new Date(), "YYYY-MM-DD")
          .add(30, "d")
          .format("YYYY-MM-DD")
        const today60 = moment(new Date(), "YYYY-MM-DD")
          .add(60, "d")
          .format("YYYY-MM-DD")
        const today90 = moment(new Date(), "YYYY-MM-DD")
          .add(90, "d")
          .format("YYYY-MM-DD")
        _.forEach(documentsFiltered, document => {
          const expiration_date = moment(
            document.expiration_date,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD")
          if (document.active) {
            if (moment(expiration_date).isBefore(today)) {
              this.documentsStadistic.experied.cantDocument++
              this.documentsStadistic.experied.percent = this.calculatePercent(
                this.documentsStadistic.experied.cantDocument,
                total
              )
            }
            if (
              moment(expiration_date).isSameOrAfter(today) &&
              moment(expiration_date).isSameOrBefore(today30)
            ) {
              this.documentsStadistic.yellow.cantDocument++
              this.documentsStadistic.yellow.percent = this.calculatePercent(
                this.documentsStadistic.yellow.cantDocument,
                total
              )
            }
            if (
              moment(expiration_date).isAfter(today30) &&
              moment(expiration_date).isSameOrBefore(today60)
            ) {
              this.documentsStadistic.blue.cantDocument++
              this.documentsStadistic.blue.percent = this.calculatePercent(
                this.documentsStadistic.blue.cantDocument,
                total
              )
            }
            if (
              moment(expiration_date).isAfter(today60) &&
              moment(expiration_date).isSameOrBefore(today90)
            ) {
              this.documentsStadistic.gray.cantDocument++
              this.documentsStadistic.gray.percent = this.calculatePercent(
                this.documentsStadistic.gray.cantDocument,
                total
              )
            }
            if (moment(expiration_date).isAfter(today90)) {
              this.documentsStadistic.green.cantDocument++
              this.documentsStadistic.green.percent = this.calculatePercent(
                this.documentsStadistic.green.cantDocument,
                total
              )
            }
          }
        })
      },
      calculatePercent(part, total) {
        return (part * 100) / total
      },
      updateConfig(newConfig) {
        this.cardConfig = { ...this.cardConfig, ...newConfig }

        this.cardConfig.id = this.id
        this.cardConfig.title = newConfig.title
        this.cardConfig.icon = newConfig.icon
        this.cardConfig.color = newConfig.color

        this.$emit("update:config", this.cardConfig)
      },
    },
  }
</script>

<style scoped>
  .v-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: auto !important;

    .v-card__text {
      padding-bottom: 0;
    }
  }

  .document-expiry__list.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none !important;
    border: 1px dashed #dadada;
    padding: 5px;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
  }

  .shadow-none {
    box-shadow: none !important;
  }

  #chart {
    max-width: 100%;
  }

  .data-chart-card {
    border-radius: 8px;
    overflow: hidden;
  }

  .title-text {
    font-weight: bold;
    margin-left: 8px;
    color: #2c3e50; /* Dark text for contrast */
  }

  .v-chip {
    border-radius: 0.3em;
  }
</style>
