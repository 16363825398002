<template>
  <v-card class="data-card" flat style="height: 100%">
    <v-card-title class="d-flex align-center ma-2 mr-0">
      <v-icon left :color="cardConfig.color">{{ cardConfig.icon }}</v-icon>
      <span class="title-text">{{ cardConfig.title }}</span>
      <v-spacer />
      <v-btn v-if="preview" @click="preview = !preview" class="my-2 px-5" color="secondary lighten-2" elevation="0" small><span class="text--secondary font-weight-bold"><v-icon left>mdi-chevron-left</v-icon>{{ $t("general.buttons.back")}}</span></v-btn>
    </v-card-title>
    <v-card-text style="height: calc(100% - 90px);">
      <v-container fluid class="fill-height">
        <v-row class="fill-height">
          <v-col class="fill-height">
            <span
              class="d-flex justify-center align-center align-content-center fill-height"
              v-if="isEditingEnabled && !preview"
            >
              <v-card
                height="250px"
                max-height="100%"
                width="100%"
                class="
                  shadow-none
                  ma-0
                  pa-0
                  my-4
                  elevation-0
                  d-flex
                  flex-column
                  justify-center
                  align-content-center align-center fill-height
                "
                elevation="0"
              >
                <v-chip disabled small>
                  <v-icon small>mdi-alert-outline</v-icon>
                  <p
                    style="font-size: 12px; font-weight: normal"
                    class="ma-0 pa-0 mx-4"
                  >
                    {{ $t('general.ui.not_data_found') }}
                  </p>
                </v-chip>
                <v-btn @click="preview = !preview" class="my-2 px-5" color="secondary lighten-2" elevation="0" small><span class="text--secondary font-weight-bold">{{$t('widgets.preview')}}<v-icon right>mdi-cellphone-link</v-icon></span></v-btn>
              </v-card>
            </span>
            <span v-else>
              <v-container fluid class="ma-0 pa-0">
                <v-row class="d-flex justify-space-between">
                  <v-col cols="4">
                    <v-text-field
                      onkeypress="return (event.charCode != 34)"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      dense
                      outlined
                      background-color="#EAEAEA80"
                      color="primary"
                      v-model="search"
                      :clearable="false"
                      @keypress.enter="searchForText"
                      @click:append="searchForText"
                      @click:clear="searchAll"
                      append-icon="mdi-magnify"
                      :label="$t('general.titles.filter')"
                      single-line
                      hide-details
                      :disabled="!!(source && source.token)"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="filtersObjects.record && filtersObjects.record.status"
                  >
                    <v-autocomplete
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      persistent-hint
                      outlined
                      dense
                      background-color="#EAEAEA80"
                      color="primary"
                      clearable
                      hide-details
                      class="mx-2"
                      item-color="primary"
                      :items="recordStatus"
                      multiple
                      item-text="name"
                      item-value="value"
                      v-model="filtersObjects.record.status"
                      small-chips
                      :label="$t('records.titles.selectStatus')"
                      @change="onSelectedStatus($event)"
                      :loading="loadingStatus"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip small v-if="index === 0">
                          <span>{{ item.name | truncate(10) }}</span>
                        </v-chip>
                        <span
                          style="font-size: 10px !important"
                          v-if="index === 1"
                          class="grey--text caption"
                          >(+{{ filtersObjects.record.status.length - 1 }}
                          {{ $t("general.titles.others") }})</span
                        >
                      </template>
                      <template v-slot:prepend-item>
                        <v-checkbox
                          v-model="checkboxState.status"
                          color="primary"
                          class="pt-2 pb-2 mt-0 pl-4"
                          :hide-details="true"
                          @change="selectAll(recordStatus)"
                        >
                          <template v-slot:label>
                            <v-btn small color="primary" x-small text>
                              {{ $t("general.titles.selectAll") }}
                            </v-btn>
                          </template>
                        </v-checkbox>
                        <v-divider class="mb-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-card class="shadow-none ma-0 pa-0 my-4 elevation-0 d-flex justify-center align-content-center align-center">
                      <v-card-text class="pa-0 ma-0">
                        <v-data-table
                          class="ma-0 pa-0"
                          :dense="false"
                          :loading="loadingRecords"
                          :no-results-text="$t('general.ui.not_search_found')"
                          :no-data-text="$t('general.ui.not_data_found')"
                          :headers="headers"
                          :items="recordsByCompany"
                          :items-per-page="4"
                          :options.sync="options"
                          :footer-props="{
                            ...rowsPerPageItemsOptions,
                            itemsPerPageText: $t(
                              'general.table.itemsPerPageText'
                            ),
                            pageText:
                              '{0}-{1} ' +
                              $t('general.table.pageText') +
                              ' {2}',
                          }"
                          :server-items-length="recordsByCompanyTotalCount"
                          :loading-text="$t('general.notification.loadData')"
                        >
                          <template v-slot:item.status="{ item }">
                            <span
                              class="dot-validated"
                              v-if="item.status === 'VALIDATED'"
                            />
                            <span
                              class="dot-validating"
                              v-if="item.status === 'VALIDATING'"
                            />
                            <span
                              class="dot-pending"
                              v-if="item.status === 'PENDING'"
                            />
                            <span
                              class="dot-errored"
                              v-if="item.status === 'ERRORED'"
                            />
                          </template>
                          <template v-slot:item.uniqueIdRepresentation="{ item }">
                            <UniqueIdRepresentationComponent :unique-id="item.uniqueIdRepresentation" />
                          </template>
                          <template v-slot:item.sdi_origin="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="ma-1" small>{{
                                  item.sdi_origin.control_label | truncate(30)
                                }}</v-chip>
                              </template>
                              <span style="font-size: 12px; color: #f5f5f5">
                                {{ $t(item.sdi_origin.control_label) }}
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-slot:item.sdi_destination="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="ma-1" small>{{
                                  item.sdi_destination.control_label
                                    | truncate(30)
                                }}</v-chip>
                              </template>
                              <span style="font-size: 12px; color: #f5f5f5">
                                {{ $t(item.sdi_destination.control_label) }}
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-slot:item.record_date_time="{ item }">
                            <span>{{
                              getDateFormat(item.record_date_time)
                            }}</span>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"
  import { CardWidgetMixin } from "@dashboard/mixins/card-widget.mixin"
  import { ReloadWidgetMixin } from "@dashboard/mixins/reload-widget.mixin"
  import i18n from "@/plugins/i18n";
  import axios from "axios";
  import _ from "lodash";
  import moment from "moment/moment";
  import UniqueIdRepresentationComponent from "@/module/records/components/UniqueIdRepresentationComponent.vue"

  export default {
    components: { UniqueIdRepresentationComponent },
    mixins: [CardWidgetMixin, ReloadWidgetMixin],
    data() {
      return {
        profile: null,
        loadingRecords: false,
        search: '',
        rowsPerPageItemsOptions: {
          itemsPerPageOptions: [4, 8, 15, 25, 50, 100],
        },
        options: {},
        message: i18n.t('general.notification.loadData'),
        loadingStatus: false,
        checkboxState: {
          status: false
        },
        preview: false
      }
    },
    watch: {
      options: {
        async handler() {
          if (this.filters) {
            this.loadingRecords = true;
            this.filters.pageableDTO = {
              page: this.options.page - 1,
              size: this.options.itemsPerPage,
              sortBy: "id",
              direction: "desc",
            };
            this.filters.storage_device_types_ids = null;
            this.filters.storageDeviceTypeDTO = null;
            this.filters.company_id = this.profile.company_id;
            this.filters.status = this.filtersObjects.record && this.filtersObjects.record.status ? this.filtersObjects.record.status : [];

            await this.searchText()
          }
        },
        deep: true,
      },
    },
    async created() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
    },
    computed: {
      ...mapGetters({
        recordsByCompany: 'records/recordsByCompany',
        recordsByCompanyTotalCount: 'records/recordsByCompanyTotalCount'
      }),

      recordStatus() {
        return [
          {
            id: 1,
            value: 'VALIDATED',
            name: i18n.t('records.filters.status.validated')
          },
          {
            id: 2,
            value: 'VALIDATING',
            name: i18n.t('records.filters.status.validating')
          },
          {
            id: 3,
            value: 'ERRORED',
            name: i18n.t('records.filters.status.errored')
          },
          {
            id: 4,
            value: 'PENDING',
            name: i18n.t('records.filters.status.pending')
          }
        ]
      },
      isEditingEnabled() {
        return this.$store.getters["dashboard/IS_EDITING_MODE"]
      },
      source: {
        get() {
          return this.$store.getters['records/source']
        },
        set(val) {
          this.$store.commit('records/SET_SOURCE', val)
        },
      },
      request: {
        get() {
          return this.$store.getters['records/request']
        },
        set(val) {
          this.$store.commit('records/SET_REQUEST', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters["ui/GET_OBJECTS_TO_FILTERS"];
        },
        set(val) {
          this.$store.dispatch("ui/SET_OBJECTS_TO_FILTERS", val);
        },
      },
      headers: () => [
        {
          text: "",
          value: "status",
          sortable: false,
        },
        {
          text: i18n.t("records.fields.code"),
          value: "uniqueIdRepresentation",
          val: "uniqueIdRepresentation",
          sortable: true,
        },
        {
          text: i18n.t("records.fields.stage"),
          value: "sdt_to_show_translate",
          type: "text",
          sortable: false,
        },
        {
          text: i18n.t("records.fields.origin"),
          value: "sdi_origin",
          type: "text",
          sortable: false,
        },
        {
          text: i18n.t("records.fields.destination"),
          value: "sdi_destination",
          type: "text",
          sortable: false,
        },
        {
          text: i18n.t("records.fields.date"),
          value: "record_date_time",
          type: "date",
          sortable: false,
        },
        {
          text: i18n.t("records.fields.process"),
          value: "process_name",
          type: "text",
          sortable: false,
          width: 200,
        },
      ],
      // Computed getter records or filter by "search" criteria
      getRecordsByCompany() {
        this.loading;
        return this.getRecodsGetters(this.search);
      },
    },
    methods: {
      ...mapActions({
        fetchListRecordsByCompanyPaginated:
          'records/fetchListAppointmentsByCompanyPaginated',
        fetchListRecordsBySearch: 'records/fetchListRecordsBySearch',
      }),
      async searchForText() {
        this.options.page = 1
        await this.searchText()
      },
      async searchAll() {
        this.options.page = 1
        this.search = null
        await this.searchText()
      },
      async searchText() {
        this.message = i18n.t('general.notification.loadData')
        this.request = true
        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        this.loadingRecords = true
        this.$emit('isDisable', this.loadingRecords)
        const keys = ['pageableDTO']
        let filters = _.pick(this.filters, keys)

        filters.companyId = this.profile.company_id
        filters.stringFilter = this.search
        filters.storageDeviceTypeDTO = null

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'asc'
            : 'desc'

        const sort = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'record_date_time';

        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        await this.fetchListRecordsByCompanyPaginated([
          filters,
          this.source,
          this.$toast,
        ])

        this.loadingRecords = false
      },
      async selectAll(status) {
        if (this.checkboxState.status) {
          this.$set(this.filtersObjects.record, 'status', _.map(status, s => s.value));
          await this.onSelectedStatus(status);
        }
        else {
          this.$set(this.filtersObjects.record, 'status', []);
          await this.onSelectedStatus([]);
        }
      },
      async onSelectedStatus(e) {
        this.loadingRecords = true

        this.checkboxState.status = e.length === this.recordStatus.length;

        if ((e && e.length > 0) && typeof e[0] === 'object')
          this.filters.status = _.map(e, s => s.value);
        else
          this.filters.status = e;


        this.$set(this.filtersObjects.record, 'status', this.filters.status);

        this.$store.commit('ui/SET_LOCAL_STORAGE_FOR_RECORDS', this.data)

        const CancelToken = axios.CancelToken;
        let source = CancelToken.source();

        const keys = [
          'pageableDTO',
          'companyId',
          'stringFilter',
          'storageDeviceTypeDTO',
          'status'
        ];

        const filters = _.pick(this.filters, keys);

        if (this.search && this.search !== '')
          filters.stringFilter = this.search

        await this.fetchListRecordsByCompanyPaginated([
          filters,
          source,
          this.$toast
        ]).finally(() => {
          this.loadingRecords = false;
        });
      },
      getRecodsGetters(search) {
        let items = this.recordsByCompany;
        if (search) {
          items = this.searchOnAll(items);
        }
        return items;
      },
      searchOnAll(items) {
        return items.filter((item) => {
          return Object.values(item)
            .join(",")
            .toUpperCase()
            .includes(this.search.toUpperCase());
        });
      },

      getDateFormat(dateString) {
        return moment(new Date(dateString)).format("DD/MM/YYYY");
      },
      replace(event){
        event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
        this.search =  event.currentTarget.value.toString().replaceAll('"','')
      },
    },

    destroyed() {
      this.message = i18n.t('general.notification.loadData')
      if (this.source) {
        this.source.cancel('cancelRequest')
        this.source = null
      }
      this.$store.commit('general/SET_ORIGINAL_STATE_CONFIG')
    }
  }
</script>

<style scoped>
  .v-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: auto !important;

    .v-card__text {
      padding-bottom: 0;
    }
  }

  .shadow-none {
    box-shadow: none !important;
  }

  .data-card {
    border-radius: 8px;
    overflow: hidden;
  }

  .title-text {
    font-weight: bold;
    margin-left: 8px;
    color: #2c3e50;
  }

  .v-chip {
    border-radius: 0.3em;
  }
</style>
