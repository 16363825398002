import { render, staticRenderFns } from "./ProducersByStateSkeleton.vue?vue&type=template&id=24669ae2&scoped=true"
import script from "./ProducersByStateSkeleton.vue?vue&type=script&lang=js"
export * from "./ProducersByStateSkeleton.vue?vue&type=script&lang=js"
import style0 from "./ProducersByStateSkeleton.vue?vue&type=style&index=0&id=24669ae2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24669ae2",
  null
  
)

export default component.exports